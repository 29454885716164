export interface Account {
  id: string
  oauthId: string
  profileId: string
  name: string
  validity: string
  reconnect: boolean
  isExpired: boolean
  type: string
  avatar: string
  platform: string
  isAppInstalled?: boolean
  settingUrl?: string
  meta?: AccountMeta
  count?: number
  checked?: boolean
}

interface AccountMeta {
  page?: {
    id?: string
    name?: string
    avatar?: string
  }
  isVerified?: boolean
  storeCode?: string
  locationId?: string
  accountId?: string
  urn?: string
  pageId?: string
  protected?: boolean
  verified?: boolean
  openId?: string
  username?: string
  userId?: string
  storefrontAddress?: {
    regionCode?: string
    languageCode?: string
    postalCode?: string
    administrativeArea?: string
    locality?: string
    addressLines?: string[]
  }
  defaultBoardId?: string
  groupId?: string
  groupName?: string
  groupUser?: string
}

export interface Group {
  id: string
  name: string
  accounts: Account[]
}

export interface YoutubePostDetails {
  privacyLevel?: YoutubePrivacyLevel
  title?: string
  type?: YoutubePostType
}

export interface PlatformMention {
  text: string
  name: string
  id: string
  startIndex?: number
  endIndex?: number
}

export interface PlatformMentionPost {
  name: string
  id: string
}

export interface PlatformOption {
  content?: string
  htmlContent: string
  ogTags?: any
  mention?: PlatformMention[]
}

export interface YoutubePlatformOption extends PlatformOption {
  postDetails: YoutubePostDetails
}
export interface PinterestPlatformOption extends PlatformOption {
  postDetails?: PinterestPostDetails
}
export interface CommunityPlatformOption extends PlatformOption {
  postDetails?: CommunityPostDetails
}

export interface LinkedinPlatformOption extends PlatformOption {
  postDetails?: LinkedinPostDetails
}

export interface PostPayload {
  title?: string
  buttonLabel?: string
  buttonLink?: string
  redeemLink?: string
  startDate?: Date
  endDate?: Date
  terms?: string
  actionType?: string
  coupon?: string
  content?: string
  images?: any[]
  medias?: any[]
  scheduleDate?: Date
  htmlContent?: string
  followUpComment?: string
  status?: string
  approvalStatus?: string
  approver?: string
  approverNote?: string
  requesterNote?: string
  google: PlatformOption
  facebook: PlatformOption
  instagram: PlatformOption
  twitter: PlatformOption
  tiktok: PlatformOption
  youtube: YoutubePlatformOption
  pinterest: PinterestPlatformOption
  community: CommunityPlatformOption
  linkedin: LinkedinPlatformOption
  recurringDetails?: IRecurringPostDetails
  tags?: string[]
  newTags?: string[]
  categoryId?: string
  applyWatermark?: boolean
  privacyLevel?: string
  enableComment?: boolean
  enableDuet?: boolean
  enableStitch?: boolean
  videoDisclosure?: boolean
  promoteYourBrand?: boolean
  promoteOtherBrand?: boolean
  type?: PostType
  scheduleTimeUpdated?: boolean
  locationTags: ILocationTag[]
}

export interface Errors {
  content?: boolean
  startDate?: boolean
  endDate?: boolean
  title?: boolean
  coupon?: boolean
  terms?: boolean
  buttonLink?: boolean
  redeemLink?: boolean
  gmbMediaInvalid?: boolean
  instagramMediaInvalid?: boolean
  instagramMediaLength?: boolean
  instagramMediaContentInvalid?: boolean
  publisherNotSelected?: boolean
  linkedinMediaInvalid?: boolean
  twitterMediaInvalid?: boolean
  twitterFollowUpInvalid?: boolean
  tiktokMediaLength?: boolean
  tiktokMediaInvalid?: boolean
  facebookMediaInvalid?: boolean
  invalidMultiMedia?: boolean
  invalidMultiMediaFB?: boolean
  invalidMultiMediaGMB?: boolean
  invalidMultiMediaInstagramReel?: boolean
  instagramReelMediaLength?: boolean
  tiktokPrivacyLevel?: boolean
  tiktokVideoDisclosure?: boolean
  youtubeMediaLength?: boolean
  pinterestMediaEmpty?: boolean
  pinterestOptionsError?: boolean
  pinterestMediaError?: boolean
}

export enum PostStatus {
  DRAFT = 'draft',
  FAILED = 'failed',
  PUBLISHED = 'published',
  SCHEDULED = 'scheduled',
  IN_REVIEW = 'in_review',
  IN_PROGRESS = 'in_progress',
  NOTIFICATION_SENT = 'notification_sent',
}

export enum CSVPostStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export enum CSVStatus {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
  IN_REVIEW = 'in_review',
  IMPORTING = 'importing',
}

export enum ApprovalStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  NOT_REQUIRED = 'not_required',
}

export enum RssPostStatus {
  ACTIVE = 'active',
  PAUSED = 'paused',
  DELETED = 'deleted',
}

export enum ReviewPostStatus {
  ACTIVE = 'active',
  PAUSED = 'paused',
  DELETED = 'deleted',
}

export interface LocationUser {
  id: string
  firstName: string
  lastName: string
  name: string
  profilePic: string
  email: string
  role: string
}

export enum MediaLimit {
  INSTAGRAM = 10,
  LINKEDIN = 9,
  TWITTER = 4,
  GOOGLE = 1,
  YOUTUBE = 1,
  PINTEREST = 1,
  COMMUNITY = 10,
  LINKEDIN_CAROUSAL = 300,
}

export enum CharLimit {
  GOOGLE = 1500,
  FACEBOOK = 63206,
  LINKEDIN = 3000,
  INSTAGRAM = 2200,
  TWITTER = 280,
  TIKTOK = 2200,
  YOUTUBE = 5000,
  TIKTOK_BUSINESS = 2200,
  PINTEREST = 500,
  COMMUNITY = 100000,
}

export enum ModalType {
  ERROR = 'send',
  PRIMARY = 'primary',
  DELETE = 'delete',
  SUCCESS = 'success',
  DELETE_RECURRING = 'deleteRecurring',
  RESUME = 'resume',
  PAUSE = 'pause',
}

export const PostCreatorActions = ['edit', 'duplicate', 'delete']

export const PostApproverActions = ['view', 'approve', 'reject']

export const ActiveRssPostActions = ['edit', 'pause', 'delete']
export const PauseRssPostActions = ['edit', 'resume', 'delete']
export const RssSkipPostActions = ['delete']

export const ActiveReviewPostActions = ['edit', 'pause', 'delete']
export const PauseReviewPostActions = ['edit', 'resume', 'delete']
export const ReviewSkipPostActions = ['delete']

export interface ICustomValue {
  id: string
  name: string
  fieldKey: string
  value: string
}

export interface ICustomField {
  id: string
  name: string
  fieldKey: string
  dataType: string
  picklistOptions: string[]
}

export const PostDuplicateActions = ['duplicate']

export const ErrorMessage = {
  VIOLATES_COMMUNITY: 'Post content violates community standards.',
}
export const CSVPostDelete = ['delete']

export enum PostType {
  POST = 'post',
  STORY = 'story',
  REEL = 'reel',
}

export enum YoutubePostType {
  VIDEO = 'video',
  SHORT = 'short',
}

export enum Platform {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  LINKEDIN = 'linkedin',
  TWITTER = 'twitter',
  TIKTOK = 'tiktok',
  TIKTOK_BUSINESS = 'tiktok-business',
  YOUTUBE = 'youtube',
  PINTEREST = 'pinterest',
  COMMUNITY = 'community',
}

export enum DisplayPlatform {
  GOOGLE = 'Google My Business',
  FACEBOOK = 'Facebook',
  INSTAGRAM = 'Instagram',
  LINKEDIN = 'Linkedin',
  TWITTER = 'Twitter',
  TIKTOK = 'TikTok',
  YOUTUBE = 'Youtube',
  PINTEREST = 'Pinterest',
  COMMUNITY = 'Community',
}

export interface ConnectAccount {
  platform: Platform
  oAuthId?: string
  reconnectAccounts?: any
  reconnectFilter?: boolean
  refresh?: string
  error?: any
}
export interface UserPermission {
  contentAiEnabled: boolean
}

export interface Features {
  contentAI: boolean
  templateLib: boolean
  imageEditor: boolean
  reviewPost: boolean
}

export interface IImageMetaData {
  width: number
  height: number
  size: number
  mimeType: string
}
export interface ConnectAccount {
  platform: Platform
  oAuthId?: string
  reconnectAccounts?: any
  reconnectFilter?: boolean
}
export interface IEmailTemplateDetail {
  label: string
  value: string
  createdAt?: string
  updatedAt?: string
  downloadUrl?: string
  isDefault?: boolean
}

export interface IRecurringPostDetails {
  recurrenceType: RecurrenceType
  repeatEvery: number
  startDate: string
  endDate: string
  weekDays?: number[]
  postTime: string
}

export enum DashboardTabTypes {
  CSV = 'csv',
  RECURRING = 'recurring',
  RECENT = 'recent',
  ALL = 'all',
  SCHEDULED = 'scheduled',
  FAILED = 'failed',
  DRAFT = 'draft',
  IN_REVIEW = 'in_review',
  RSS = 'rss',
  OTHER = 'other',
}

export enum RecurrenceType {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}
export interface FacebookGroupPages {
  id: string
  name: string
  avatar: string
  disabled?: boolean
}

export interface SelectBaseOption {
  label: string
  value: string
  imageUrl?: string
  description?: string
  style?: any
  disabled?: boolean
}

export enum PostSource {
  CSV = 'csv',
  CUSTOMIZED = 'customized',
  POST = 'post',
  RECURRING = 'recurring',
}

export enum BackendPostSource {
  RSS = 'rss',
  REVIEW = 'review',
}

export enum ContextualTypeKeys {
  GENERATE_CONTENT = 'generate_content',
  IMPROVE_WRITING = 'improve_writing',
  FIX_SPELLING_GRAMMAR = 'fix_spelling_grammar',
  SIMPLIFY_WRITING = 'simplify_writing',
  MAKE_LONGER = 'make_longer',
  MAKE_SHORTER = 'make_shorter',
}

export interface IQuickAction {
  id?: string
  selectedText?: string
  contextualType?: ContextualTypeKeys
}

export enum QuickActionSubmitOptions {
  REPLACE = 'replace',
  ADD_BELOW = 'add_below',
  CLOSE = 'close',
}
export interface IPagination {
  skip: number
  limit: number
  total?: number
  query?: string
}
export interface ICustomValueOptions {
  label: string
  value: string
  children?: ICustomValueOptions[]
}

export enum AccountType {
  PAGE = 'page',
  GROUP = 'group',
  PROFILE = 'profile',
  LOCATION = 'location',
  BUSINESS = 'business',
}

export enum PostTabs {
  CSV = 'csv',
  POST = 'post-list',
  RECURRING = 'recurring',
  REVIEW = 'review',
  RSS = 'rss',
  APPROVAL = 'approval',
  TEMPLATE_LIBRARY = 'template-library',
  CALENDAR = 'calendar',
}

export enum YoutubePrivacyLevel {
  PRIVATE = 'private',
  PUBLIC = 'public',
  UNLISTED = 'unlisted',
}
export interface PinterestBoardItem {
  id: string
  name: string
  privacy: string
  media: { image_cover_url: string }
}

interface PinterestAccountBoards {
  [accountId: string]: string
}

export interface PinterestPostDetails {
  link?: string
  title?: string
  boardIds?: PinterestAccountBoards
}

export interface PostAsUser {
  id: string
  name: string
  avatar: string
}

export interface CommunityPostDetails {
  title?: string
  notifyAllGroupMembers?: boolean
  postAsUser?: { [key: string]: PostAsUser }
}

export interface TokenData {
  tokenId: string
  authTokenV2: string
}

export interface ILocationTag {
  id?: string
  name?: string
  userId: string
}

export interface LinkedinPostDetails {
  postAsPdf?: boolean
  pdfTitle?: string
}

export enum FileProcessingStatus {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
}
