<script setup lang="ts">
import { UIModal, UIModalContent, UIModalHeader } from '@gohighlevel/ghl-ui'
import useEmitter from '../../compositions/useEmitter'
import { useSocialMediaStore } from '../../store'
import { Platform } from '../../types'

const emit = defineEmits(['close'])
const store = useSocialMediaStore()
const eventBus = useEmitter()

const props = defineProps({
  modalShow: {
    type: Boolean,
    required: true,
  },
})

function connectAccount(accountType: string) {
  if (accountType == 'tiktok') {
    eventBus.emit('showTiktokModal')
  } else if (accountType == 'instagram') {
    eventBus.emit('ConnectInstagramAccountInfo')
  } else if (accountType == 'community') {
    eventBus.emit('ConnectCommunityAccount')
  } else {
    store.connectAccount(accountType)
  }
  closeModal()
}

function closeModal() {
  emit('close')
}
</script>

<template>
  <UIModal :show="modalShow" width="650">
    <template #header>
      <UIModalHeader
        type="success"
        :title="$t('addSocialAccountModal.title')"
        description=""
        @close="closeModal"
        id="modal-header-add-account"
      >
      </UIModalHeader>
    </template>
    <UIModalContent class="flex justify-center">
      <div class="flex justify-center">
        <div
          id="social-planner-add-account-gmb"
          class="flex items-center justify-center h-14 w-14 rounded-full bg-white cursor-pointer mr-2"
          style="box-shadow: 0px 1px 27px rgba(0, 0, 0, 0.13)"
          @click.prevent="connectAccount('google')"
        >
          <img src="/src/assets/img/icon-gmb.svg" />
        </div>

        <div
          id="social-planner-add-account-facebook"
          class="flex items-center justify-center h-14 w-14 rounded-full bg-white cursor-pointer ml-2 mr-2"
          style="box-shadow: 0px 1px 27px rgba(0, 0, 0, 0.13)"
          @click.prevent="connectAccount('facebook')"
        >
          <img src="/src/assets/img/icon-fb.svg" />
        </div>

        <div
          id="social-planner-add-account-instagram"
          class="flex items-center justify-center h-14 w-14 rounded-full bg-white cursor-pointer mr-2 ml-2"
          style="box-shadow: 0px 1px 27px rgba(0, 0, 0, 0.13)"
          @click.prevent="connectAccount('instagram')"
        >
          <img src="/src/assets/img/icon-instagram.svg" />
        </div>

        <div
          id="social-planner-add-account-linkedin"
          class="flex items-center justify-center h-14 w-14 rounded-full bg-white cursor-pointer mr-2 ml-2"
          style="box-shadow: 0px 1px 27px rgba(0, 0, 0, 0.13)"
          @click.prevent="connectAccount('linkedin')"
        >
          <img src="/src/assets/img/icon-linkedin.svg" />
        </div>

        <div
          id="social-planner-add-account-tiktok"
          class="flex items-center justify-center h-14 w-14 rounded-full bg-white cursor-pointer ml-2"
          style="box-shadow: 0px 1px 27px rgba(0, 0, 0, 0.13)"
          @click.prevent="connectAccount('tiktok')"
        >
          <img class="w-10 h-10" src="/src/assets/img/icon-tiktok.svg" />
        </div>

        <div
          id="social-planner-add-account-youtube"
          class="flex items-center justify-center h-14 w-14 rounded-full bg-white cursor-pointer ml-2"
          style="box-shadow: 0px 1px 27px rgba(0, 0, 0, 0.13)"
          @click.prevent="connectAccount(Platform.YOUTUBE)"
        >
          <img class="w-10 h-10" src="/src/assets/img/icon-youtube.svg" />
        </div>

        <div
          id="social-planner-add-account-pinterest"
          class="flex items-center justify-center h-14 w-14 rounded-full bg-white cursor-pointer ml-2"
          style="box-shadow: 0px 1px 27px rgba(0, 0, 0, 0.13)"
          @click.prevent="connectAccount('pinterest')"
        >
          <img src="/src/assets/img/icon-pinterest.svg" class="w-9 h-9" />
        </div>
        <div
          id="social-planner-add-account-community"
          class="flex items-center justify-center h-14 w-14 rounded-full bg-white cursor-pointer ml-2"
          style="box-shadow: 0px 1px 27px rgba(0, 0, 0, 0.13)"
          @click.prevent="connectAccount('community')"
        >
          <img src="/src/assets/img/icon-community.svg" class="w-9 h-9" />
        </div>
      </div>
    </UIModalContent>
    <!--  content goes here  -->
    <template #footer>
      <div class="mx-auto">
        <div
          class="mx-auto flex flex-col items-center justify-center text-xs text-gray-400"
        >
          <span>{{ $t('addSocialAccountModal.description') }}</span>
        </div>
      </div>
    </template>
  </UIModal>
</template>

<style lang="scss">
.tiktok-header {
  @apply pt-0 pb-2 justify-start px-2 space-x-0;
}
.tiktok-body {
  @apply py-2 justify-start space-x-4 flex flex-row px-2;
}
</style>
